import React from 'react';
import PropTypes from 'prop-types';
import { Select, Tooltip, message } from 'antd';

const { Option } = Select;

import { toArray, toTitleCase } from '../../utils/helper';
import GMBtypes from '../../shared/data/types.json';

const MAX_TAG_COUNT = 1;

export default function SelectType({ value, onChange, size, loading, types, placeholder = 'Category', style = { minWidth: '170px', maxWidth: '260px' } }) {
  const arrayValue = toArray(value);
  const selectedLength = arrayValue.length;
  const title = selectedLength > MAX_TAG_COUNT ? arrayValue.join(', ') : null;
  const isMaxValues = selectedLength > 100;

  function handleShowError() {
    message.error('The maximum amount of options selected');
  }

  return (
    <Tooltip
      placement='right'
      mouseEnterDelay={0.3}
      title={title}
    >
      <Select
        allowClear
        autoFocus
        showSearch
        maxTagCount={MAX_TAG_COUNT}
        mode='multiple'
        tokenSeparators={[',', '\n']}
        loading={loading}
        style={style}
        size={size}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        optionFilterProp='children'
        {...(isMaxValues && { open: false, onDropdownVisibleChange: handleShowError })}
      >
        {(types || GMBtypes).map(r => <Option key={r} value={r}>{toTitleCase(r)}</Option>)}
      </Select>
    </Tooltip>
  );
}

SelectType.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  loading: PropTypes.bool,
  types: PropTypes.array,
  placeholder: PropTypes.string,
  style: PropTypes.object,
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDiv = styled.div`
  padding-top: 20px;
  padding-left: 15px;
  font-size: 20px;
`;

export default function SearchStatus({ loading, loadingTotal, total, searched }) {
  const exportDisabled = loading || loadingTotal;
  const formatedTotal = total || 0;

  return <StyledDiv>
    {exportDisabled ?
      <>Calculating total results. Please wait before downloading the results...</>
      : searched ?
        total > 0 ?
          <>
            Found about <b>{formatedTotal.toLocaleString()}</b> results.
            You can export the data by clicking the &quot;<b>Download Data</b>&quot; button and <b>selecting the amount</b>.
          </>
          :
          <>
            It looks like there are no matches for your search.
          </>
        :
        <strong>Please click the &quot;<em>Search</em>&quot; button to apply the new filters.</strong>
    }
  </StyledDiv>;
}

SearchStatus.propTypes = {
  loading: PropTypes.bool,
  loadingTotal: PropTypes.bool,
  total: PropTypes.number,
  searched: PropTypes.bool,
};

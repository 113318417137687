import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Tooltip, notification } from 'antd';
import { isEmpty } from 'lodash';

import { toArray, toTitleCase } from '../../utils/helper';
import apiPlaces from '../../api/apiPlaces';
import { usePrevious } from '../../utils/hooks';

const { Option } = Select;
const MAX_TAG_COUNT = 1;

export default function SelectCounty({ value, onChange, fitlers, isPlaces }) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const previousFitlers = usePrevious(fitlers);

  const arrayValue = toArray(value);
  const selectedLength = arrayValue.length;
  const title = selectedLength > MAX_TAG_COUNT ? arrayValue.join(', ') : null;

  useEffect(() => {
    if (isEmpty(fitlers)) {
      setOptions([]);
    } else {
      const { c } = fitlers;

      if (isEmpty(c)) {
        setOptions([]);
      } else {
        const { c: previousCity  } = previousFitlers || {};
        if (c !== previousCity) {
          setOptions([]);
          fetchData();
        }
      }
    }
  }, [fitlers]);

  async function fetchData() {
    setLoading(true);
    try {
      if (isPlaces) {
        const data = await apiPlaces.fetchPlacesOptions('county', fitlers);
        setOptions(data);
      } else {
        const data = await apiPlaces.fetchCompaniesOptions('county', fitlers);
        setOptions(data);
      }
    } catch (error) {
      notification.error({ message: error.message });
    }
    setLoading(false);
  }

  return (
    <Tooltip
      placement='right'
      mouseEnterDelay={0.3}
      title={title}
    >
      <Select
        allowClear
        showSearch
        maxTagCount={1}
        mode='tags'
        tokenSeparators={[',']}
        style={{ minWidth: '160px' }}
        loading={loading}
        size='large'
        placeholder='Neighborhoods / district'
        disabled={isEmpty(options) && loading}
        value={value}
        onChange={onChange}
        optionFilterProp='children'
        notFoundContent={null}
      >
        {options.map(r => <Option key={r} value={r}>{toTitleCase(r)}</Option>)}
      </Select>
    </Tooltip>
  );
}

SelectCounty.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  fitlers: PropTypes.object.isRequired,
  isPlaces: PropTypes.bool,
};

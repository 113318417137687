import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import qs from 'query-string';

import CheckoutModal from './CheckoutModal/CheckoutModal';

export default function ExportButton({ totalResults, loading, disabled, searchParams, title = 'Download', type = 'primary', productName = 'Places', units = 'rows' }) {
  const [modalVisible, setModalVisible] = useState(false);
  const loadingProfile = useSelector(state => state.profileReducer.loading);
  const description = 'You can choose the exact amount in the next step.';

  useEffect(() => {
    const { checkoutQuantity } = qs.parse(window.location.search);
    if (checkoutQuantity) {
      setModalVisible(true);
    }
  }, []);

  return <>
    <Button
      title={description}
      size='large'
      className='export'
      type={type}
      loading={loading || loadingProfile}
      disabled={disabled}
      onClick={() => setModalVisible(!modalVisible)}
      icon={<DownloadOutlined />}
    >{title}</Button>
    <CheckoutModal
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      totalResults={totalResults}
      searchParams={searchParams}
      productName={productName}
      units={units}
    />
  </>;
}

ExportButton.propTypes = {
  totalResults: PropTypes.number,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  searchParams: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
  productName: PropTypes.string,
  units: PropTypes.string,
  auth: PropTypes.object,
};

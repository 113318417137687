import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Collapse, DatePicker, Select, Tag, Checkbox, Space, Switch, InputNumber } from 'antd';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { checkIfPaidUser } from '../../utils/utils';
import Mixpanel from '../../analytics/mixpanel';
import SelectType from '../Common/SelectType';
import SelectPostalCode from '../Common/SelectPostalCode';
import SelectCityOrTown from '../Common/SelectCityOrTown';
import SelectCounty from '../Common/SelectCounty';
import SelectCountry from '../Common/SelectCountry';
import SelectState from '../Common/SelectState';
import GeoFiltersItems from './GeoFiltersItems';
import ExportButton from '../Common/ExportButton';
import InfoTooltip from '../../shared/Components/InfoTooltip';
import SearchStatus from '../Common/SearchStatus';
import AttributesFilter from '../Common/AttributesFilter';
import PremiumFeatureTooltip from '../../shared/Components/PremiumFeatureTooltip';
import { useDimensions } from '../../utils/hooks';

const StyledCollapse = styled(Collapse)`
  margin-bottom: -20px;
  font-size: 20px;
  font-weight: 600;
`;
// const mobileFilterCountryCodes = new Set([
//   'IT', 'UA', 'FR', 'ES', 'SE', 'SZ', 'TR', 'AE', 'BR',
//   'IN', 'ID', 'PH', 'IE', 'IL', 'PL', 'PT', 'RU', 'SG',
//   'ZA', 'TW', 'TH', 'VN', 'CO', 'EG', 'HK', 'MY', 'NL',
//   'NZ', 'PE', 'CL', 'US',
// ]);

const advancedFormWidth = 230;
const advancedItemStyle = { width: 250 };

const { Panel } = Collapse;
const { Option, OptGroup } = Select;
const priceFilterableTypes = new Set(['restaurant', 'pizza', 'bar']);
const emailAndPhoneUniquePhoneOptions = new Set(['enter_phone', 'with_phone', 'email_and_phone']);
const emailAndPhoneUniqueDomainOptions = new Set(['with_email', 'email_and_phone']);

const uniqueInfoTooltip = <InfoTooltip
  title={<>Selecting this will return only one entry per business, even if they have multiple locations using the same email and domain.</>}
/>;

export default function Search({ searchParams, onChange, size, loading, onSearch, exporting, total, loadingTotal, hideControls, searched, setSearched, placesShowing }) {
  const { cc, state, c, county, t, it, geoFilters, addedFrom, price, rating, reviews, revenue, employees, postalCode, businessStatus, public: isPublic, email, contactCategory, phone, emailAndPhone, number, uniqueDomain, uniquePhone, site, domain, name, nameExclude, os_id, located, att, area, verified } = searchParams;
  const formatedAddedFrom = addedFrom ? moment.unix(addedFrom) : null;
  const isGeoFilters = geoFilters && geoFilters.length > 0;
  const exportDisabled = loading || loadingTotal || total === 0 || placesShowing === 0;
  const advancedParametersCount = [addedFrom, postalCode, price, rating, reviews, revenue, employees, businessStatus, isPublic, email, contactCategory, phone, emailAndPhone, site, domain, name, it, att, os_id, located, area].filter(x => x).length;
  const priceSelectable = (Array.isArray(t) ? t : [t] || []).every(r => priceFilterableTypes.has(r));
  const [active, setActive] = useState(null);
  const isPaidUser = checkIfPaidUser();
  const uniquePhoneEnabled = emailAndPhoneUniquePhoneOptions.has(emailAndPhone);

  const reviewsText = reviews ? reviews.toString() : '';
  const reviewsNumber = reviewsText ? reviewsText.replace(/[+-]/g, '') : 10;
  const reviewsGreaterOrLess = reviewsText.includes('+') ? '+' : '-';

  const width = useDimensions();

  function onSearchHandle() {
    onSearch({ ...searchParams });
    setSearched(true);
    setActive(null);
    Mixpanel.track('Search click', searchParams);
  }

  function onChangeHandle(value) {
    onChange(value);
    setSearched(false);
  }

  function onCountryChange(countryCode) {
    delete searchParams.state;
    delete searchParams.c;
    delete searchParams.county;
    delete searchParams.mobile;

    onChangeHandle({ ...searchParams, cc: countryCode });
  }

  function onAddedFromChange(newAddedFrom) {
    if (newAddedFrom) newAddedFrom.set({ hour:0, minute:0, second:0, millisecond:0 });
    onChangeHandle({ ...searchParams, addedFrom: newAddedFrom ? Date.parse(newAddedFrom) / 1000 : undefined });
  }

  function disabledCutoffDate(current) {
    return current > moment().subtract(1, 'week');
  }

  function onStateChange(newState) {
    delete searchParams.c;
    delete searchParams.county;
    onChangeHandle({ ...searchParams, state: newState });
  }

  function onCollapseChange() {
    setActive(active ? null : 'advanced');
  }

  function onReviewsChange(reviewsNumber, reviewsGreaterOrLess) {
    onChangeHandle({ ...searchParams, reviews: `${reviewsNumber}${reviewsGreaterOrLess}` });
  }

  const selectReviewsInputBefore = (
    <Select defaultValue={reviewsGreaterOrLess} onChange={(v) => onReviewsChange(reviewsNumber, v)}>
      <Option value='+'>Greater or equal</Option>
      <Option value='-'>Less or equal</Option>
    </Select>
  );

  return <>
    <Form name='search-form' layout={width > 1155 ? 'inline' : 'vertical'} className='top-search-bar' >
      {!hideControls && <>
        <Form.Item style={{ paddingBottom: width > 1155 ? 10 : 0 }}>
          <SelectType
            size='large'
            value={t}
            onChange={(v) => onChangeHandle({ ...searchParams, t: v })}
          />
        </Form.Item>
        {isGeoFilters ?
          <GeoFiltersItems value={geoFilters}/>
          :
          <Form.Item>
            <Input.Group className='search_country_group' compact style={{ display: 'flex', gap: width > 1155 ? 5 : 0, margin: 0 }}>
              <Form.Item>
                <SelectCountry
                  value={cc}
                  onChange={onCountryChange}
                />
              </Form.Item>
              {cc === 'US' &&
                <Form.Item >
                  <SelectState
                    placeholder='State'
                    value={state}
                    onChange={onStateChange}
                  />
                </Form.Item>
              }
              <Form.Item className='city-or-town-input'>
                <SelectCityOrTown
                  isPlaces
                  fitlers={{ cc, state }}
                  value={c}
                  onChange={(value) => onChangeHandle({ ...searchParams, c: value })}
                />
              </Form.Item>
              {!isEmpty(c) &&
                <Form.Item>
                  <SelectCounty
                    isPlaces
                    fitlers={{ cc, state, c }}
                    value={county}
                    onChange={(value) => onChangeHandle({ ...searchParams, county: value })}
                  />
                </Form.Item>
              }
            </Input.Group>
          </Form.Item>
        }

        <Form.Item>
          <Button
            className='search-button'
            htmlType='submit'
            title='Make a search'
            type={searched ? 'default' : 'primary'}
            size='large'
            loading={loading}
            icon={<SearchOutlined/>}
            onClick={onSearchHandle}
          >Search</Button>
        </Form.Item>
      </>}

      <Form.Item>
        <ExportButton
          productName='Places'
          type={searched ? 'primary' : 'default'}
          totalResults={total}
          loading={exporting || loadingTotal}
          searchParams={searchParams}
          disabled={exportDisabled}
        />
      </Form.Item>
    </Form>

    {!hideControls && <div style={{ backgroundColor: '#AFAFA' }}>
      <StyledCollapse ghost activeKey={active} onChange={onCollapseChange} expandIcon={!active && (() => <FilterOutlined/>)}>
        <Panel
          key='advanced'
          header={'Advanced filters' + (advancedParametersCount > 0 ? ` (${advancedParametersCount})` : '')}
        >
          <Form name='advanced-form' className='advanced-form' size='small' labelCol={{ span: 100 }}>
            <Form.Item label='Business Name'>
              <Space direction='horizontal'>
                <Input
                  size={size}
                  style={advancedItemStyle}
                  placeholder='Name or keyword'
                  value={name}
                  onChange={({ target: { value } }) => onChangeHandle({ ...searchParams, name: value ? value : undefined })}
                />
                <Switch
                  size='default'
                  checkedChildren='Included'
                  unCheckedChildren='Excluded'
                  checked={!nameExclude}
                  onChange={() => onChangeHandle({ ...searchParams, nameExclude: nameExclude ? undefined : true })}
                />
              </Space>
            </Form.Item>
            <Form.Item label='Business Status'>
              <Select
                placeholder='All'
                allowClear
                value={businessStatus}
                style={advancedItemStyle}
                onChange={(v) => onChangeHandle({ ...searchParams, businessStatus: v })}
              >
                <Option key='operational' value='operational'>Operational</Option>
                <Option key='closed_temporarily' value='closed_temporarily'>Temporarily closed</Option>
                <Option key='closed_permanently' value='closed_permanently'>Permanently closed</Option>
                <OptGroup label='Mixed'>
                  <Option key='closed_temporarily,operational' value='closed_temporarily,operational'>Operational + Temporarily closed</Option>
                </OptGroup>
              </Select>
            </Form.Item>
            <Form.Item label='Company Type'>
              <Select
                placeholder='All'
                allowClear
                value={isPublic}
                style={advancedItemStyle}
                onChange={(v) => onChangeHandle({ ...searchParams, public: v })}
              >
                <Option key='true' value='true'>Public</Option>
                <Option key='false' value='false'>Private</Option>
              </Select>
            </Form.Item>
            <Form.Item label='Email/Phone'>
              <Space>
                <Select
                  placeholder='All'
                  allowClear
                  value={emailAndPhone}
                  style={advancedItemStyle}
                  onChange={(v) => onChangeHandle({
                    ...searchParams,
                    emailAndPhone: v,
                    uniqueDomain: emailAndPhoneUniqueDomainOptions.has(v) ? uniqueDomain : undefined,
                    number: v === 'enter_phone' ? number : undefined,
                    uniquePhone: emailAndPhoneUniquePhoneOptions.has(v) ? uniquePhone : undefined
                  })}
                >
                  <Option key='with_email' value='with_email'>With email</Option>
                  <Option key='without_email' value='without_email'>Without email</Option>
                  <Option key='with_phone' value='with_phone'>With phone</Option>
                  <Option key='without_phone' value='without_phone'>Without phone</Option>
                  <Option key='enter_phone' value='enter_phone'>Enter phone</Option>
                  <Option key='email_and_phone' value='email_and_phone'>With email and phone</Option>
                  <Option key='email_or_phone' value='email_or_phone'>With email or phone</Option>
                </Select>
                {(emailAndPhone === 'enter_phone' || number) &&
                  <Input
                    size={size}
                    style={advancedItemStyle}
                    placeholder='123'
                    value={number}
                    onChange={({ target: { value } }) => onChangeHandle({ ...searchParams, number: value ? value : undefined })}
                  />
                }
                { uniquePhoneEnabled &&
                  <Checkbox
                    disabled={!number && emailAndPhone === 'enter_phone'}
                    checked={uniquePhone}
                    onChange={() => onChangeHandle({ ...searchParams, uniquePhone: uniquePhone ? undefined : true })}
                  >Unique phone</Checkbox>
                }
                {/* {mobileFilterCountryCodes.has(cc) && (emailAndPhone === 'with_phone' || emailAndPhone === 'email_and_phone') &&
                  <Select
                    placeholder='Type'
                    allowClear
                    value={mobile}
                    style={{ ...advancedItemStyle, width: 100 }}
                    onChange={(v) => onChangeHandle({ ...searchParams, mobile: v })}
                  >
                    <Option key='true' value='true'>Mobile</Option>
                    <Option key='false' value='false'>Fixed line</Option>
                  </Select>
                } */}
                {emailAndPhone === 'with_email' && <>
                  <Checkbox
                    checked={uniqueDomain}
                    onChange={() => onChangeHandle({ ...searchParams, uniqueDomain: uniqueDomain ? undefined : true })}
                  >Unique only</Checkbox>
                  {uniqueInfoTooltip}
                </>}
              </Space>
            </Form.Item>
            <Form.Item label='Preferred Contacts'>
              <PremiumFeatureTooltip isPaidUser={isPaidUser}>
                <Select
                  disabled={!isPaidUser}
                  allowClear
                  placeholder='All'
                  mode='tags'
                  maxTagCount={1}
                  tokenSeparators={[',']}
                  value={contactCategory}
                  style={advancedItemStyle}
                  onChange={(v) => onChangeHandle({ ...searchParams, contactCategory: v })}
                >
                  <Option key='decision makers' value='decision makers'>Decision makers</Option>
                  <Option key='influencers' value='influencers'>Influencers</Option>
                  <Option key='procurement/purchasing' value='procurement/purchasing'>Procurement/purchasing</Option>
                  <Option key='technical' value='technical'>Technical</Option>
                  <Option key='finance' value='finance'>Finance</Option>
                  <Option key='operations' value='operations'>Operations</Option>
                  <Option key='marketing' value='marketing'>Marketing</Option>
                  <Option key='sales' value='sales'>Sales</Option>
                  <Option key='maintenance' value='maintenance'>Maintenance</Option>
                  <Option key='human resources' value='human resources'>Human resources</Option>
                  <Option key='legal and compliance' value='legal and compliance'>Legal and compliance</Option>
                  <Option key='supply chain/logistics' value='supply chain/logistics'>Supply chain/logistics</Option>
                  <Option key='education/training' value='education/training'>Education/training</Option>
                </Select>
              </PremiumFeatureTooltip>
            </Form.Item>
            <Form.Item label='Website'>
              <PremiumFeatureTooltip isPaidUser={isPaidUser}>
                <Space>
                  <Select
                    disabled={!isPaidUser}
                    placeholder='All'
                    allowClear
                    value={domain ? 'domain' : site}
                    style={advancedItemStyle}
                    onChange={(v) => onChangeHandle({ ...searchParams, site: v, domain: null, uniqueDomain: v === 'true' || email == 'true' ? uniqueDomain : undefined })}
                  >
                    <Option key='true' value='true'>With website</Option>
                    <Option key='false' value='false'>Without website</Option>
                    <Option key='domain' value='domain'>Enter domain</Option>
                  </Select>
                  {(site === 'domain' || domain) &&
                    <Input
                      size={size}
                      style={advancedItemStyle}
                      placeholder='starbucks.com'
                      value={domain}
                      onChange={({ target: { value } }) => onChangeHandle({ ...searchParams, domain: value })}
                    />
                  }
                  {site === 'true' && <>
                    <Checkbox
                      checked={uniqueDomain}
                      onChange={() => onChangeHandle({ ...searchParams, uniqueDomain: uniqueDomain ? undefined : true })}
                    >Unique only</Checkbox>
                    {uniqueInfoTooltip}
                  </>}
                </Space>
              </PremiumFeatureTooltip>
            </Form.Item>
            {priceSelectable &&
              <Form.Item label='Price'>
                <Select
                  allowClear
                  placeholder='Select price'
                  mode='multiple'
                  value={price}
                  maxTagCount={1}
                  style={advancedItemStyle}
                  onChange={(v) => onChangeHandle({ ...searchParams, price: v })}
                >
                  <Option key='$' value='$'>$</Option>
                  <Option key='$$' value='$$'>$$</Option>
                  <Option key='$$$' value='$$$'>$$$</Option>
                  <Option key='$$$$' value='$$$$'>$$$$</Option>
                </Select>
              </Form.Item>
            }
            <Form.Item label='Rating'>
              <Select
                allowClear
                placeholder='Select rating'
                value={rating}
                style={advancedItemStyle}
                onChange={(v) => onChangeHandle({ ...searchParams, rating: v })}
              >
                <OptGroup label='Greater or equal'>
                  <Option key='2+' value='2+'>2.0+</Option>
                  <Option key='3+' value='3+'>3.0+</Option>
                  <Option key='4+' value='4+'>4.0+</Option>
                  <Option key='4.5+' value='4.5+'>4.5+</Option>
                </OptGroup>
                <OptGroup label='Less or equal'>
                  <Option key='1-' value='1-'>1-</Option>
                  <Option key='1.5-' value='1.5-'>1.5-</Option>
                  <Option key='2-' value='2-'>2.0-</Option>
                  <Option key='3-' value='3-'>3.0-</Option>
                  <Option key='4-' value='4-'>4.0-</Option>
                </OptGroup>
              </Select>
            </Form.Item>
            <Form.Item label='Reviews'>
              <Select
                allowClear
                placeholder='Select reviews count'
                value={reviews}
                style={advancedItemStyle}
                onChange={(v) => onChangeHandle({ ...searchParams, reviews: v })}
              >
                <OptGroup label='Range'>
                  <Option key='0' value='0'>No reviews</Option>
                  <Option key='1' value='1'>1 - 10</Option>
                  <Option key='1..' value='1..'>1 - 100</Option>
                  <Option key='1...' value='1...'>1 - 1 000</Option>
                  <Option key='1....' value='1....'>1 - 10 000</Option>
                  <Option key='10' value='10'>10 - 100</Option>
                  <Option key='10..' value='10..'>10 - 1000</Option>
                  <Option key='10...' value='10...'>10 - 10 000</Option>
                  <Option key='100' value='100'>100 - 1 000</Option>
                  <Option key='100..' value='100..'>100 - 10 000</Option>
                  <Option key='1000' value='1000'>1 000 - 10 000</Option>
                </OptGroup>
                <OptGroup label='Enter value'>
                  <Option key='enter' value={`${reviewsNumber}${reviewsGreaterOrLess}`}>Enter reviews</Option>
                </OptGroup>
              </Select>
              {(reviews?.includes('+') || reviews?.includes('-')) &&
                  <InputNumber
                    type='number'
                    addonBefore={selectReviewsInputBefore}
                    placeholder='50'
                    value={Number(reviewsNumber)}
                    onChange={(value) => {
                      onReviewsChange(Math.abs(Number(value)), reviewsGreaterOrLess);
                    }}
                  />
              }
            </Form.Item>
            <Form.Item label='Revenue'>
              <PremiumFeatureTooltip isPaidUser={isPaidUser}>
                <Select
                  allowClear
                  disabled={!isPaidUser}
                  placeholder='Select revenue'
                  value={revenue}
                  style={advancedItemStyle}
                  onChange={(v) => onChangeHandle({ ...searchParams, revenue: v })}
                >
                  <OptGroup label='Range'>
                    <Option key='1000000' value='1000000'>1 - 10M</Option>
                    <Option key='10000000' value='10000000'>10 - 100M</Option>
                    <Option key='100000000' value='100000000'>100 - 1B</Option>
                  </OptGroup>
                  <OptGroup label='Greater or equal'>
                    <Option key='1000000+' value='1000000+'>1M and more</Option>
                    <Option key='10000000+' value='10000000+'>10M and more</Option>
                    <Option key='100000000+' value='100000000+'>100M and more</Option>
                    <Option key='1000000000+' value='1000000000+'>1B and more</Option>
                  </OptGroup>
                  <OptGroup label='Less or equal'>
                    <Option key='1000000-' value='1000000-'>up to 1M</Option>
                    <Option key='10000000-' value='10000000-'>up to 10M</Option>
                    <Option key='100000000-' value='100000000-'>up to 100M</Option>
                    <Option key='1000000000-' value='1000000000-'>up to 1B</Option>
                  </OptGroup>
                </Select>
              </PremiumFeatureTooltip>
            </Form.Item>
            <Form.Item label='Employees'>
              <PremiumFeatureTooltip isPaidUser={isPaidUser}>
                <Select
                  allowClear
                  disabled={!isPaidUser}
                  placeholder='Select employee count'
                  value={employees}
                  style={advancedItemStyle}
                  onChange={(v) => onChangeHandle({ ...searchParams, employees: v })}
                >
                  <OptGroup label='Range'>
                    <Option key='1' value='1'>1 - 10</Option>
                    <Option key='10' value='10'>10 - 100</Option>
                    <Option key='100' value='100'>100 - 1000</Option>
                  </OptGroup>
                  <OptGroup label='Greater or equal'>
                    <Option key='10+' value='10+'>10</Option>
                    <Option key='100+' value='100+'>100</Option>
                    <Option key='1000+' value='1000+'>1000</Option>
                    <Option key='10000+' value='10000+'>10,000</Option>
                    <Option key='100000+' value='100000+'>100,000</Option>
                  </OptGroup>
                  <OptGroup label='Less or equal'>
                    <Option key='10-' value='10-'>up to 10</Option>
                    <Option key='100-' value='100-'>up to 100</Option>
                    <Option key='1000-' value='1000-'>up to 1,000</Option>
                    <Option key='10000-' value='10000-'>up to 10,000</Option>
                    <Option key='100000-' value='100000-'>up to 100,000</Option>
                  </OptGroup>
                </Select>
              </PremiumFeatureTooltip>
            </Form.Item>
            <Form.Item label='Postal Code'>
              <SelectPostalCode
                size={size}
                value={postalCode}
                width={advancedFormWidth}
                onChange={(v) => onChangeHandle({ ...searchParams, postalCode: v })}
              />
            </Form.Item>
            <Form.Item label='Added From (Date)'>
              <PremiumFeatureTooltip isPaidUser={isPaidUser}>
                <DatePicker
                  disabled={!isPaidUser}
                  inputReadOnly
                  format='YYYY/MM/DD'
                  value={formatedAddedFrom}
                  onChange={onAddedFromChange}
                  disabledDate={disabledCutoffDate}
                  style={{ width: advancedFormWidth }}
                />
                <InfoTooltip
                  title={<>
                    Looking for newly started companies? Find and connect with businesses that have just opened.<br/><br/>
                    The accuracy rate is about 95% - 99%, as it works based on when the place is found by the system, and not when the place was actually added.
                  </>}
                />
              </PremiumFeatureTooltip>
            </Form.Item>
            <Form.Item label='Exclude'>
              <SelectType
                placeholder='Select category'
                style={{ width: advancedFormWidth }}
                size={size}
                value={it}
                onChange={(v) => onChangeHandle({ ...searchParams, it: v })}
              />
            </Form.Item>
            <Form.Item label='Attributes'>
              <AttributesFilter
                value={att}
                fitlers={searchParams}
                onChange={(v) => onChangeHandle({ ...searchParams, att: v })}
              />
            </Form.Item>
            <Form.Item label='Physical Location'>
              <Select
                placeholder='All'
                allowClear
                value={area}
                style={advancedItemStyle}
                onChange={(v) => onChangeHandle({ ...searchParams, area: v })}
              >
                <Option key='true' value='true'>Area service (without location)</Option>
                <Option key='false' value='false'>Physical location</Option>
              </Select>
            </Form.Item>
            <Form.Item label='Verified'>
              <Select
                placeholder='All'
                allowClear
                value={verified}
                style={advancedItemStyle}
                onChange={(v) => onChangeHandle({ ...searchParams, verified: v })}
              >
                <Option key='true' value='true'>Verified</Option>
                <Option key='false' value='false'>Unverified</Option>
              </Select>
              <InfoTooltip title='Indicates if the place was claimed by owner'/>
            </Form.Item>
            {located && <Form.Item label='Located In ID'>
              <Tag closable onClose={() => onChangeHandle({ ...searchParams, located: undefined })}>{located}</Tag>
            </Form.Item>}
            {os_id && <Form.Item label='ID'>
              <Tag closable onClose={() => onChangeHandle({ ...searchParams, os_id: undefined })}>{os_id}</Tag>
            </Form.Item>}
          </Form>
        </Panel>
      </StyledCollapse>
    </div>}
    <SearchStatus total={total} loading={loading} loadingTotal={loadingTotal} searched={searched} />
  </>;
}

Search.propTypes = {
  searchParams: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  size: PropTypes.string,
  loading: PropTypes.bool,
  exporting: PropTypes.bool,
  total: PropTypes.number,
  loadingTotal: PropTypes.bool,
  hideControls: PropTypes.bool,
  setSearched: PropTypes.func,
  searched: PropTypes.bool,
  placesShowing: PropTypes.number,
};

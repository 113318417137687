import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from 'antd';

import { handleOnCopy } from '../../utils/helper';

const SpanShadows = styled.span`
  color: transparent;
  text-shadow: rgba(0, 0, 0, 0.7) 0px 0px 10px;
`;

export default function HidedValue({ value = '' }) {
  const groups = [];
  const shadowFlags = [];

  const valueLength = value ? value.length : 0;
  let group = [];

  for (var i = 0; i < valueLength; i++) {
    const character = value[i];
    const nextCharacter = i + 1 < valueLength ? value[i + 1] : null;
    group.push(character);

    let isShadow = character === '*';
    if (!nextCharacter || (isShadow ? nextCharacter !== '*' : nextCharacter === '*')) {
      groups.push(group);
      shadowFlags.push(isShadow);
      group = [];
    }
  }

  return <Tooltip title='You can get this data by clicking the "Download Data" button'>
    {groups.map((text, i) => <span key={i}>{shadowFlags[i] ? <SpanShadows>{text}</SpanShadows> : <span onCopy={handleOnCopy}>{text}</span>}</span>)}
  </Tooltip>;
}

HidedValue.propTypes = {
  value: PropTypes.string,
};

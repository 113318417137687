export function isValidEmail(email) {
  return /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(email);
}

export function getLanguage(defaultLanguage='en') {
  return localStorage.getItem('i18nextLng') || defaultLanguage;
}

export function updateLocalStorage(key, value) {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
}

export function setPaidUser(value) {
  updateLocalStorage('paidUser', JSON.stringify(value));
}

export function checkIfPaidUser() {
  return localStorage.getItem('paidUser') === 'true';
}

export function isRecentlyUsedNTimes(key, n = 3, timeLimitInMinutes = 15) {
  let storedTimestamps = JSON.parse(localStorage.getItem(key)) || [];

  const currentTime = new Date().getTime();
  const timeLimitInMilliseconds = timeLimitInMinutes * 60 * 1000;
  storedTimestamps = storedTimestamps.filter(timestamp => currentTime - timestamp <= timeLimitInMilliseconds);

  storedTimestamps.push(currentTime);

  storedTimestamps = storedTimestamps.filter(timestamp => currentTime - timestamp <= timeLimitInMilliseconds);

  localStorage.setItem(key, JSON.stringify(storedTimestamps));

  return storedTimestamps.length > n;
}

export function removeParamFromUrl(param) {
  const url = new URL(window.location);
  url.searchParams.delete(param);
  window.history.replaceState({}, '', url);
}

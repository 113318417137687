/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { notification, Col, Row, Card } from 'antd';
import qs from 'query-string';
import { saveAs } from 'file-saver';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';

import history from '../../utils/history';
import { removeEmptyValues } from '../../helpers/common';
import apiPlaces from '../../api/apiPlaces';
import AuthModal from '../Common/AuthModal';
import ProfileHeader from '../Navigation/ProfileHeader';
import SearchResults from './SearchResults';
import Search from './Search';

const StyledCol = styled(Col)`
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
`;

const PAGE_SIZE = 10;
const defaultParams = { cc: 'US' };

export default function Businesses({ location, auth }) {
  const [searchParams, setSearchParams] = useState({  ...defaultParams, ...qs.parse(location.search), limit: PAGE_SIZE });
  const { view } = searchParams;
  const { authenticated } = auth;
  const hideControls = view === 'true';

  const [authModalVisible, setAuthModalVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingEstimate, setLoadingEstimate] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(0);
  const [selectableTypes, setSelectableTypes] = useState([]);
  const [loadingSelections, setLoadingSelections] = useState(true);
  const [total, setTotal] = useState(null);
  const [searched, setSearched] = useState(true);

  const skip = parseInt(searchParams.skip) || 0;
  const pageSize = parseInt(searchParams.limit) || 0;

  useEffect(() => {
    document.title = 'Businesses & Executives - marketing information';

    fetchData(searchParams, true);
    onSearchParamsChange(searchParams);
    fetchIndustrySelections();
  }, []);

  async function fetchData(params, fetchEstimate = true) {
    setLoading(true);
    try {
      const data = await apiPlaces.fetchCompanies(params);
      setItems(data.data);
    } catch (error) {
      notification.error({ message: error.message });
    }
    setLoading(false);

    if (fetchEstimate) await fetchDataEstimate(params);
  }

  async function fetchDataEstimate(params) {
    setLoadingEstimate(true);
    try {
      const data = await apiPlaces.fetchCompaniesEstimate(params);
      setTotal(data.total);
    } catch (error) {
      notification.error({ message: error.message });
    }
    setLoadingEstimate(false);
  }

  async function fetchIndustrySelections() {
    setLoadingSelections(true);
    try {
      const data = await apiPlaces.fetchCompaniesIndustrySelections();
      setSelectableTypes(data);
    } catch (error) {
      notification.error({ message: error.message });
    }
    setLoadingSelections(false);
  }

  async function onExport(resultType, limit) {
    setExporting(true);
    try {
      const data = await apiPlaces.exportCompanies({ ...searchParams, resultType, limit });
      saveAs(data, `businesses_${limit}.${resultType}`);
    } catch(error) {
      notification.error({ message: error.message });
    }
    setExporting(false);
  }

  function onSearchParamsChange(params) {
    const newParams = cloneDeep(params);
    removeEmptyValues(newParams);
    setSearchParams(newParams);

    if (!newParams.geoFilters) {
      history.push({ search: `?${qs.stringify(newParams)}` });
    } else {
      const shortParams = cloneDeep(newParams);
      delete shortParams.geoFilters;
      history.push({ search: `?${qs.stringify(shortParams)}` });
    }
  }

  async function onSearch(params) {
    if ('skip' in params) delete params.skip; // remove skip when change params

    onSearchParamsChange(params);
    await fetchData(params);
  }

  async function onPagination({ current, pageSize }) {
    const newParams = { ...searchParams, skip: (current - 1) * pageSize };
    if (newParams.skip === 0) delete newParams.skip;
    if (newParams.skip > 90 && !authenticated && !authModalVisible) setAuthModalVisible(true);

    onSearchParamsChange(newParams);
    await fetchData(newParams, false);
  }

  function handleSearchParamsChange(value) {
    onSearchParamsChange({ ...searchParams, ...value });
    setSearched(false);
  }

  return (
    <>
      <Card>
        <ProfileHeader auth={auth} />
        <Search
          searched={searched}
          setSearched={setSearched}
          loading={loading}
          exporting={exporting}
          loadingTotal={loadingEstimate}
          searchParams={searchParams}
          total={total}
          onExport={onExport}
          onSearch={onSearch}
          onChange={onSearchParamsChange}
          hideControls={hideControls}
          selectableTypes={selectableTypes}
          loadingSelections={loadingSelections}
        />
      </Card>

      <Row>
        <StyledCol span={24}>
          <SearchResults
            loading={loading}
            exporting={exporting}
            places={items}
            total={total}
            skip={skip}
            pageSize={pageSize}
            onPagination={onPagination}
            onExport={onExport}
            searchParams={searchParams}
            onSelect={setSelectedPlace}
            onSearchParamsChange={handleSearchParamsChange}
          />
        </StyledCol>
      </Row>
      <AuthModal visible={authModalVisible} onClose={() => setAuthModalVisible(false)} />
    </>
  );
}

Businesses.propTypes = {
  location: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

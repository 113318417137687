import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Popconfirm, Tooltip, Drawer, Row, Result, Col, notification } from 'antd';
import { Trans } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import Mixpanel from '../../../analytics/mixpanel';
import PricingCards from '../PricingCards';
import { downloadDemo, fetchPrices, fetchProfile, checkoutWithBalance } from '../../../redux/actions/profileActions';
import AddCredits from '../../Profile/AddCredits/AddCredits';
import Testimonials from '../../Others/Testmonials';
import ConfirmStage from './ConfirmStage';
import { removeParamFromUrl } from '../../../utils/utils';

const DEFAULT_QUANTITY = 10000;

const StyledSpan = styled.span`
  padding-inline: 8px;
`;

export default function CheckoutModal({
  open, onCancel, searchParams, totalResults = 0, productName = 'Places', units = 'rows', prelaodProfile = true
}) {
  const { skip: defaultSkip = 0, checkoutQuantity } = searchParams;

  const loadingProfile = useSelector(state => state.profileReducer.loading);
  const { balance = 0 } = useSelector(state => state.profileReducer.profile);
  const prices = useSelector(state => state.profileReducer.prices[productName]) || [];
  const currentUsage = parseInt(localStorage.getItem(`usage-${productName}`) || 0);
  const dispatch = useDispatch();

  const [pricesOpened, setPricesOpened] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [shake, setShake] = useState(false);
  const loading = loadingProfile || waiting;
  const [type, setType] = useState('csv');
  const [skip, setSkip] = useState(0);
  const [quantity, setQuantity] = useState(DEFAULT_QUANTITY);

  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [isPaymentStage, setIsPaymentStage] = useState(false);

  const previousPrice = calculatePrice ? calculatePrice(currentUsage) : 0;
  const price = calculatePrice(quantity + currentUsage) - previousPrice;
  const toDeposit = Math.max(Math.ceil((price - balance) * 100) / 100, 1);
  const isEnoughCredits = balance >= price;
  const isAuthenticated = !!localStorage.getItem('isLoggedIn');
  const emailVerified = localStorage.getItem('emailVerified') == 'true';

  useEffect(() => {
    dispatch(fetchPrices(productName));
    if (isAuthenticated && prelaodProfile) dispatch(fetchProfile());
  }, []);

  useEffect(() => {
    if (open) {
      if (!prelaodProfile) {
        dispatch(fetchProfile());
      }

      const newSkip = totalResults > 1 ? parseInt(defaultSkip) : 0;
      setQuantity(Math.min(DEFAULT_QUANTITY, totalResults - newSkip || DEFAULT_QUANTITY));
      setSkip(newSkip);

      if (checkoutQuantity) {
        const newCheckoutQuantity = parseInt(checkoutQuantity);
        removeParamFromUrl('checkoutQuantity');
        delete searchParams.checkoutQuantity;
        setQuantity(newCheckoutQuantity);
        handleOnConfirm(newCheckoutQuantity);
      }
    }
  }, [open]);

  function onDownloadDemo() {
    dispatch(downloadDemo(searchParams, quantity, type, productName));
  }

  function onSetQuantity(v) {
    setQuantity(v);
    if (skip > 0 && totalResults - v < skip) {
      setSkip(totalResults - v);
    }
  }

  function onSetSkip(v) {
    setSkip(v);
    if (quantity + v > totalResults) {
      setQuantity(totalResults - v);
    }
  }

  async function onConfirm() {
    await handleOnConfirm(quantity);
  }

  async function handleOnConfirm(checkoutQuantity) {
    if (isAuthenticated) {
      if (isEnoughCredits || isPaymentStage) {
        const status = await onCheckout(checkoutQuantity);
        if (status === 'low_balance') {
          if (isPaymentStage) {
            dispatch(fetchProfile());
            setConfirmDisabled(true);
            setShake(true);
            setTimeout(() => setShake(false), 500);
          } else {
            setIsPaymentStage(true);
          }
        }
      } else {
        setIsPaymentStage(true);
      }
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      if (skip > 0) queryParams.set('skip', skip.toString());
      const redirect = `${window.location.pathname}?${queryParams.toString()}&checkoutQuantity=${checkoutQuantity}`;
      const link = `/signup?redirect=${encodeURIComponent(redirect)}`;
      if (window.self !== window.top) {
        window.open(link, '_blank').focus();
      } else {
        window.location = link;
      }
    }
  }

  async function onCheckout(quantity) {
    return await dispatch(checkoutWithBalance({ ...searchParams, skip }, quantity, totalResults, type, productName, units));
  }

  function onLogin() {
    const { pathname, search = '' } = window.location;
    const link = `/login?redirect=${encodeURIComponent(pathname + search)}`;

    if (window.self !== window.top) {
      window.open(link, '_blank').focus();
    } else {
      window.location = link;
    }

    Mixpanel.track('Login (from checkout)');
  }

  function calculatePrice(usage) {
    let price = 0;
    let previousUpTo = 0;

    for (let i = 0; i < prices.length; i++) {
      const { up_to, unit_amount_decimal } = prices[i];

      if (up_to && usage > up_to) {
        price += (up_to - previousUpTo) * unit_amount_decimal;
        previousUpTo = up_to;
      } else {
        return price + (usage - previousUpTo) * unit_amount_decimal;
      }
    }

    return price;
  }

  function handleOnCancel() {
    setIsPaymentStage(false);
    onCancel();
  }

  function handleCancel() {
    setIsPaymentStage(false);
  }

  async function onActionCallback(autoCheckout) {
    if (autoCheckout) {
      await waitForPaymentAndCheckout();
    } else {
      setConfirmDisabled(false);
    }
  }

  async function waitForPaymentAndCheckout() {
    setWaiting(true);

    let attempts = 0;
    while (attempts < 24) {
      attempts++;
      const { balance } = await dispatch(fetchProfile());
      if (balance >= price) {
        await onCheckout(quantity);
        setWaiting(false);
        return;
      }
      await new Promise(resolve => setTimeout(resolve, 5000));
    }

    setWaiting(false);
    notification.error({ message: 'Payment was not completed' });
  }

  let modalClassName = 'checkout_modal';
  if (shake) modalClassName += ' shake';
  if (isPaymentStage) modalClassName += ' payment_stage';

  return <Modal
    destroyOnClose
    closable={!waiting}
    style={{ top: 30 }}
    width={!isPaymentStage ? 560 : 800}
    className={modalClassName}
    title={isPaymentStage ? `Add $${toDeposit.toLocaleString()} Credits To Your Account` : 'Export Your Data'}
    okButtonProps={{ loading }}
    open={open}
    onCancel={handleOnCancel}
    footer={isPaymentStage ? [
      <Button key='back' onClick={handleCancel} className='cancel' loading={waiting}>
        <Trans i18nKey='action.cancel'>Cancel</Trans>
      </Button>,
      <Button
        type='primary'
        key='confirm'
        className='confirm'
        disabled={confirmDisabled}
        onClick={onConfirm}
        loading={loading}
      >
        <Trans i18nKey='action.confirm'>Confirm</Trans>
      </Button>
    ] : [
      <StyledSpan key='login'>
        {!isAuthenticated && (
          <Tooltip title='Login to your account to be able to use your credits'>
            <Button
              ghost
              type='primary'
              className='login'
              icon={<UserOutlined />}
              onClick={onLogin}
              title='Login to your account'
            >Login</Button>
          </Tooltip>
        )}
      </StyledSpan>,
      <Tooltip
        key='demo'
        mouseEnterDelay={3}
        title='Download the demo file to see the results example'
      >
        <Button
          ghost
          type='primary'
          className='demo'
          loading={loading}
          onClick={onDownloadDemo}
          title='Download demo results'
        >Demo</Button>
      </Tooltip>,
      <StyledSpan key='download'>
        {isAuthenticated ? (
          <Popconfirm key='download' title={`Are you sure to checkout ${quantity.toLocaleString()} items?`} onConfirm={onConfirm}>
            <Button
              key='download'
              type='primary'
              className='checkout-authenticated'
              title='Checkout and download data'
              loading={loading}
              onClick={isEnoughCredits ? null : onConfirm}
            >{`Checkout ${quantity.toLocaleString()} Rows`}</Button>
          </Popconfirm>
        ) : (
          <Button
            key='download'
            type='primary'
            className='checkout'
            loading={loading}
            onClick={onConfirm}
            title='Checkout and download results'
          >{`Checkout ${quantity.toLocaleString()} Rows`}</Button>
        )}
      </StyledSpan>,
    ]}
  >
    {isPaymentStage ?
      <Row align='middle' justify='center'>
        <Col style={{ maxWidth: 600 }}> <Result icon={null} extra={
          <AddCredits
            fixedAmount
            secondaryTab
            defaultValue={toDeposit}
            loading={loading}
            callback={onActionCallback}
            emailVerified={emailVerified}
          />
        }/></Col>
        <Col flex='auto'><Testimonials/></Col>
      </Row>
      :
      <ConfirmStage
        type={type}
        quantity={quantity}
        skip={skip}
        loading={loading}
        price={price}
        totalResults={totalResults}
        setType={setType}
        onSetQuantity={onSetQuantity}
        setSkip={onSetSkip}
        setPricesOpened={setPricesOpened}
      />
    }

    <Drawer
      width={590}
      title='Pricing'
      onClose={() => setPricesOpened(false)}
      open={pricesOpened}
    >
      <PricingCards value={prices} unitName='biz' />
    </Drawer>
  </Modal>;
}

CheckoutModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  totalResults: PropTypes.number,
  searchParams: PropTypes.object,
  productName: PropTypes.string,
  units: PropTypes.string,
  prelaodProfile: PropTypes.bool,
};

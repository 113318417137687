import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { message, Button, Card, Form, Input, Select } from 'antd';
import { isEmpty } from 'lodash';

import { regions } from '../../shared/data/regions';
import { fetchBillingInfo, updateBillingInfo, fetchProfile } from '../../redux/actions/profileActions';

const { Option } = Select;
const cardStyle = { width: 400 };

export default function BillingInformation({ location = {} }) {
  const { search } = location;
  const { session_id, checkout, secondary_tab } = queryString.parse(search);

  const billingInfo = useSelector(state => state.profileReducer.billingInfo);
  const loading = useSelector(state => state.profileReducer.loading);
  const dispatch = useDispatch();

  const [formUpdated, setFormUpdated] = useState(false);
  const [edited, setEdited] = useState(false);
  const { address, name, description } = billingInfo;

  const [form] = Form.useForm();

  if (!formUpdated && !isEmpty(billingInfo)) {
    setFormUpdated(true);
    form.setFieldsValue(billingInfo);
  }

  useEffect(() => {
    document.title = 'Billing Information';

    if (checkout === 'success' && session_id) {
      if (secondary_tab && secondary_tab === 'true') {
        message.success('Payment method connected! Return to the previous tab to start your task', 30);
      } else {
        message.success('Payment method connected');
      }

      fetchData();
    } else {
      fetchData();
    }
  }, []);

  function fetchData() {
    dispatch(fetchBillingInfo());
    dispatch(fetchProfile());
  }

  function onUpdateBillingInfo() {
    dispatch(updateBillingInfo(form.getFieldsValue()));
  }

  function onFormChange() {
    setEdited(true);
  }

  return (
    <>
      <Card
        title='Billing Information (optional, for invoices)'
        style={cardStyle}
      >
        <Form
          form={form}
          name='billing_information'
          initialValues={{ address, name, description }}
          onFieldsChange={onFormChange}
        >
          <Form.Item title='Name' name='name'>
            <Input placeholder='Legal Name, Tax ID, VAT, etc.'/>
          </Form.Item>
          <Form.Item title='Country' name={['address', 'country']}>
            <Select showSearch optionFilterProp='children' placeholder='Country'>
              {regions.map(r => <Option key={r.value} value={r.value}>{r.name} ({r.value})</Option>)}
            </Select>
          </Form.Item>
          <Form.Item title='Address 1' name={['address', 'line1']}>
            <Input placeholder='Address line 1'/>
          </Form.Item>
          <Form.Item title='Address 2' name={['address', 'line2']}>
            <Input placeholder='Address line 2'/>
          </Form.Item>
          <Form.Item title='City' name={['address', 'city']}>
            <Input placeholder='City'/>
          </Form.Item>
          <Form.Item title='Postal Code' name={['address', 'postal_code']}>
            <Input placeholder='Postal code'/>
          </Form.Item>
          <Form.Item title='State' name={['address', 'state']}>
            <Input placeholder='State'/>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ borderRadius: '4px' }}
              title='Save Information'
              type={edited ? 'primary' : 'default'}
              loading={loading}
              onClick={onUpdateBillingInfo}>Update Information</Button>
          </Form.Item>
        </Form>
      </Card>
      <br/><br/>
    </>
  );
}

BillingInformation.propTypes = {
  location: PropTypes.object.isRequired,
  authForwarded: PropTypes.object.isRequired,
};

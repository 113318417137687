import React from 'react';
import styled from 'styled-components';
import AppLogo from '../../assets/app/logo.png';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 0 10px 0;
`;

const StyledLogo = styled.img`
  width: 150px;
`;

export default function Header() {
  const shouldHideLogo = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.has('hideLogo');
  };

  return (
    <>
      {!shouldHideLogo() && (
        <StyledDiv className='desktop'>
          <StyledLogo src={AppLogo} alt='targetron logo' />
        </StyledDiv>
      )}
    </>
  );
}
